import React from "react";
import { Link } from "react-router-dom";
import Picture from "../components/Picture";
import useDarkMode from "../hook/useDarkMode.js";
import {
  aime,
  bacco,
  beach,
  bodega,
  cambridgeApartments,
  dcHouse,
  dopeHouse,
  downtownStreets,
  esplanade,
  fiveHundredBoylston,
  grandCanyon,
  hills,
  sandDunes,
  mountains,
  northEndApartments,
  palmTrees,
  reykjavik,
  shoes,
  southie,
  stateHouse,
  unionStation,
  waterfall,
  window,
  aqueduct,
} from "../Images/imports";

const Film = () => {
  useDarkMode();
  return (
    <div>
      <div
        className="bg-white dark:bg-black bg-top bg-cover h-screen text-sky-700 dark:text-emerald-800 font-PangramBold text-7xl text-center \
				flex justify-center items-center"
        style={{ transition: "height 999999s" }}
      >
        <Link to={"/"}>
          <div className="absolute top-4 left-4 text-xs md:text-sm text-sky-700 dark:text-emerald-800 font-PangramBold">
            Back to Home
          </div>
        </Link>
        <div className="">
          <h1 className="lg:text-8xl xl:text-9xl 2xl:text-11xl px-4">
            FILM GALLERY
          </h1>
          <h2 className="text-lg md:text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl pt-1">
            Less of the mood. More of the feeling
          </h2>
        </div>
      </div>

      <section className="bg-white dark:bg-black grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-y-1 md:gap-y-0 overflow-hidden">
        <Picture title={unionStation} alt="film photography of union station" />
        <Picture title={southie} alt="film photography of south boston" />
        <Picture title={aqueduct} alt="film photography of spain aqueduct" />
        <Picture title={aime} alt="film photography of aime leon dore sign" />

        <Picture title={esplanade} alt="film photography of colorado mountains" />
        <Picture title={window} alt="film photography of clock tower" />
        <Picture title={reykjavik} alt="film photography of reykjavik iceland" />
        <Picture title={beach} alt="film photography of two buildings in boston" />

        <Picture title={mountains} alt="film photography of clock tower" />
        <Picture title={bacco} alt="film photography of flowers" />
        <Picture title={bodega} alt="film photography of bodega boston" />
        <Picture title={grandCanyon} alt="film photography of bacco north end boston" />

        <Picture title={hills} alt="film photography of beacon hill boston" />
        <Picture title={waterfall} alt="film photography of union station" />
        <Picture title={downtownStreets} alt="film photography of donwtown boston" />
        <Picture title={cambridgeApartments} alt="film photography of grand canyon" />

        <Picture title={sandDunes} alt="film photography of sand dunes" />
        <Picture title={dcHouse} alt="film photography of charles river boston" />
        <Picture title={stateHouse} alt="film photography of massachusetts state house" />
        <Picture title={fiveHundredBoylston} alt="film photography of five hundred boylston street" />

        <Picture title={northEndApartments} alt="film photography of boston skyline" />
        <Picture title={dopeHouse} alt="film photography of reykjavik iceland" />
        <Picture title={palmTrees} alt="film photography of charles river boston" />
        <Picture title={shoes} alt="film photography of massachusetts state house" />

      </section>
    </div>
  );
};

export default Film;
